<template>
    <div
        class="Datepicker"
        :class="[
            { 'is-small': size === 'small' },
            { minimal: variant === 'minimal' },
            { inline: variant === 'inline' },
            { 'has-quick-actions': canUseQuickActions },
        ]"
    >
        <portal to="datePicker">
            <div
                class="DatePickerBackdrop"
                :class="{ 'is-active': datePickerIsOpen }"
                @click="closeDatepicker"
            ></div>
        </portal>
        <flat-pickr
            ref="calendar"
            class="flatpickr-input"
            v-model="date"
            :config="config"
            :class="[{ active: datePickerIsOpen }, { 'is-month-picker': monthPicker }]"
            @onChange="handleDateChange"
        >
        </flat-pickr>
        <div v-if="variant === 'inline'" class="InlineDatePickerTrigger" @click="toggleDatepicker">
            <slot></slot>
        </div>
        <div class="QuickActions" v-if="canUseQuickActions">
            <button @click="handleQuickAction('currentYear')">{{ currentYear }}</button>
            <button @click="handleQuickAction('lastMonth')">{{ lastMonth }}</button>
            <button @click="handleQuickAction('currentMonth')">{{ currentMonth }}</button>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-if="variant === 'minimal'" v-bind:svg-inline="''" v-bind:width="'24'" v-bind:height="'24'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M17 3h4a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"/></svg>
        <div
            v-else-if="variant !== 'inline'"
            class="Wrapper Range"
            :class="[{ 'is-month-picker': monthPicker }]"
            @click="monthPicker ? toggleDatepicker() : null"
        >
            <input
                :class="[
                    { 'is-set': from.length > 0 },
                    { 'is-empty': from.length === 0 },
                    { 'is-month-picker': monthPicker },
                ]"
                @blur="handleManualInput"
                :value="manualDateInput"
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:width="'26'" v-bind:height="'33'" @click="toggleDatepicker" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M17 3h4a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"/></svg>
        </div>
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins//monthSelect/style.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { German } from 'flatpickr/dist/l10n/de.js';

import {
    parse,
    format,
    isValid,
    startOfMonth,
    endOfMonth,
    subDays,
    subMonths,
    startOfYear,
    subYears,
    endOfYear,
    isBefore,
} from 'date-fns';
import { de } from 'date-fns/locale';
import { isValidDate } from 'rrule/dist/esm/dateutil';

export default {
    components: {
        flatPickr,
    },
    props: {
        onDateChange: {
            type: Function,
            default: () => {},
        },
        monthPicker: Boolean,
        dayPicker: Boolean,
        startDate: {
            type: Date,
            default: () => subDays(new Date(), 30),
        },
        endDate: {
            type: Date,
        },
        variant: {
            type: String,
            default: 'default',
        },
        size: {
            type: String,
            default: 'small',
        },
        hasQuickActions: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            date: new Date(),
            from: this.monthPicker
                ? format(new Date(this.startDate), 'LLLL - yyy', { locale: de })
                : format(new Date(this.startDate), 'dd.MM.yyyy', { locale: de }),
            to: format(new Date(), 'dd.MM.yyyy', { locale: de }),
            svgFill: 'var(--color-text-black)',
            datePickerIsOpen: false,
            config: {
                defaultDate: this.startDate,
                maxDate: new Date(),
                dateFormat: this.monthPicker ? 'F - Y' : 'd.m.Y',
                mode: this.dayPicker || this.monthPicker ? 'single' : 'range',
                locale: German,
                onChange: this.handleDateChange,
                disableMobile: true,
                plugins: [
                    this.monthPicker
                        ? new monthSelectPlugin({
                              shorthand: false,
                              dateFormat: 'F - Y',
                              altFormat: 'M Y',
                          })
                        : () => ({}),
                ],
            },
        };
    },
    computed: {
        canUseQuickActions() {
            return this.hasQuickActions && (!this.monthPicker && this.variant !== 'inline');
        },
        currentMonth() {
            return format(new Date(), 'LLLL', { locale: de });
        },
        lastMonth() {
            return format(subMonths(new Date(), 1), 'LLLL', { locale: de });
        },
        currentYear() {
            return format(new Date(), 'yyyy', { locale: de });
        },
        manualDateInput() {
            const dateFormat = this.monthPicker ? 'LLLL - yyyy' : 'dd.MM.yyyy';
            if (!this.from) return '';
            const fromDate = isValidDate(this.from)
                ? this.from
                : parse(this.from, dateFormat, new Date(), { locale: de });
            const toDate =
                this.to && isValidDate(this.to)
                    ? this.to
                    : parse(this.to, dateFormat, new Date(), { locale: de }) || new Date();
            if (this.monthPicker) {
                return this.formatDate(fromDate);
            }
            return fromDate && toDate
                ? `${this.formatDate(fromDate)} - ${this.formatDate(toDate)}`
                : fromDate
                ? this.formatDate(fromDate)
                : new Date();
        },
    },
    watch: {
        manualDateInput(val) {},
        startDate: function(val) {
            if (!val) return;
            this.from = this.monthPicker
                ? format(new Date(val), 'LLLL - yyy', { locale: de })
                : format(new Date(val), 'dd.MM.yyyy', { locale: de });
        },
        endDate: function(val) {
            if (!val) return;
            this.to = this.monthPicker
                ? format(new Date(val), 'LLLL - yyy', { locale: de })
                : format(new Date(val), 'dd.MM.yyyy', { locale: de });
        },
        date: function(val) {
            if (!val) return;

            let date = val.split('bis');
            this.from = date?.[0]?.trim();
            this.svgFill = '#616C9F';
            if (!date[1]) {
                this.svgFill = 'transparent';
            }
            this.to = (date[1] && date[1].trim()) || '';
        },
    },
    methods: {
        handleQuickAction(action) {
            let from = new Date();
            let to = new Date();

            switch (action) {
                case 'currentMonth':
                    from = startOfMonth(from);
                    to = new Date(); // Current date for ongoing month
                    break;
                case 'currentYear':
                    from = startOfYear(from);
                    to = new Date(); // Current date for the ongoing year
                    break;
                case 'lastMonth':
                    from = startOfMonth(subMonths(new Date(), 1));
                    to = endOfMonth(subMonths(new Date(), 1));
                    break;
                case 'lastYear':
                    from = startOfYear(subYears(new Date(), 1));
                    to = endOfYear(subYears(new Date(), 1));
                    break;
                default:
                    console.warn('Unknown quick action:', action);
                    return;
            }
            this.$emit('onChange', { from, to });
        },
        toggleDatepicker() {
            if (this.datePickerIsOpen) {
                this.picker.close();
                this.datePickerIsOpen = false;
            } else {
                this.picker.open();
                this.datePickerIsOpen = true;
            }
        },
        closeDatepicker() {
            this.picker.close();
            this.datePickerIsOpen = false;
        },
        handleManualInput(e) {
            let value = e.target.value;

            const regex = /[^\d\s.-]/gi;
            if (regex.test(value)) {
                value = value.replace(regex, '');
            }
            this.parseManualDateInput(value);
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        formatDate(date) {
            const dateFormat = this.monthPicker ? 'LLLL - yyyy' : 'dd.MM.yyyy';
            return format(date, dateFormat, { locale: de });
        },

        parseDate(dateString, formatString, referenceDate = new Date()) {
            return parse(dateString, formatString, referenceDate, { locale: de });
        },
        normalizeYear(year) {
            const yearInt = parseInt(year, 10);
            return year.length === 2 ? (yearInt > 50 ? 1900 + yearInt : 2000 + yearInt) : yearInt; // 2-digit year
        },
        async parseManualDateInput(input) {
            const monthRegex = /^([a-zäöüßA-ZÄÖÜß]+)\s?-?\s?([a-zäöüßA-ZÄÖÜß]*)?\s?(\d{4})?$/i;
            const dateRangeRegex = /^(\d{1,2})\s([a-zäöüßA-ZÄÖÜ]+)\s?-\s?(\d{1,2})?\s?([a-zäöüßA-ZÄÖÜ]+)?$/;
            const dateRangeNumericalRegex = /^(\d{1,2})\.(\d{1,2})\.(\d{2}|\d{4})\s*-\s*(\d{1,2})\.(\d{1,2})\.(\d{2}|\d{4})$/; // Added support for 2 or 4 digit years

            const sanitizedInput = input.trim().toLowerCase();

            let fromDate, toDate;
            const currentYear = new Date().getFullYear();

            // Case 1: "Month - Month Year" format (e.g., "August - September")
            if (monthRegex.test(sanitizedInput)) {
                const [, startMonth, endMonth, year] = sanitizedInput.match(monthRegex);
                const parsedYear = year || currentYear;
                fromDate = startOfMonth(
                    this.parseDate(
                        this.capitalizeFirstLetter(startMonth),
                        'LLLL',
                        new Date(parsedYear, 0, 1),
                    ),
                );
                toDate = endMonth
                    ? endOfMonth(
                          this.parseDate(
                              this.capitalizeFirstLetter(endMonth),
                              'LLLL',
                              new Date(parsedYear, 0, 1),
                          ),
                      )
                    : endOfMonth(fromDate);

                // Case 2: "Day Month - Day Month" format (e.g., "01 Januar - 03 Februar")
            } else if (dateRangeRegex.test(sanitizedInput)) {
                const [, startDay, startMonth, endDay, endMonth] = sanitizedInput.match(
                    dateRangeRegex,
                );
                fromDate = this.parseDate(
                    `${startDay} ${this.capitalizeFirstLetter(startMonth)} ${currentYear}`,
                    'd MMMM yyyy',
                );
                toDate =
                    endDay && endMonth
                        ? this.parseDate(
                              `${endDay} ${this.capitalizeFirstLetter(endMonth)} ${currentYear}`,
                              'd MMMM yyyy',
                          )
                        : fromDate;
                // Case 3: "Numerical Date Range" format (e.g., "01.01.2024 - 03.02.2024")
            } else if (dateRangeNumericalRegex.test(sanitizedInput)) {
                const [
                    ,
                    startDay,
                    startMonth,
                    startYear,
                    endDay,
                    endMonth,
                    endYear,
                ] = sanitizedInput.match(dateRangeNumericalRegex);

                const normalizedStartYear = this.normalizeYear(startYear);
                const normalizedEndYear = this.normalizeYear(endYear);
                fromDate = this.parseDate(
                    `${startDay}.${startMonth}.${normalizedStartYear}`,
                    'd.M.yyyy',
                );
                toDate = this.parseDate(`${endDay}.${endMonth}.${normalizedEndYear}`, 'd.M.yyyy');
            }
            if (isValid(fromDate) && isValid(toDate)) {
                if (!isBefore(fromDate, toDate)) {
                    [fromDate, toDate] = [toDate, fromDate];
                }
                this.from = fromDate;
                this.to = toDate;
                this.$emit('onChange', { from: this.from, to: this.to });
            } else {
                console.warn('Invalid manual input, could not parse dates.');

                this.from = new Date(this.parseDate(this.from, 'd.M.yyyy'));
                this.to = new Date(this.parseDate(this.to, 'd.M.yyyy'));
            }
        },
        handleDateChange(dates) {
            if (dates && dates.length === 2) {
                const range = { from: dates[0], to: dates[1] };
                this.from = dates[0];
                this.to = dates[1];
                this.$emit('onChange', range);
                this.closeDatepicker();
            } else if (dates && dates.length === 1 && this.monthPicker) {
                const range = { from: dates[0], to: endOfMonth(dates[0]) };
                this.from = range.from;
                this.to = range.to;
                this.$emit('onChange', range);
                this.closeDatepicker();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.calendar = this.$refs.calendar?.$el;

            if (this.calendar) {
                this.picker = flatpickr(this.calendar, this.config);
                this.picker.clear();
            } else {
                console.error('Unable to access calendar ref.');
            }
        });
    },
};
</script>

<style lang="scss">
.Datepicker {
    position: relative;
    display: inline-flex;
    align-items: center;
    z-index: 2;

    &.minimal {
        .Range.is-set {
            width: 100%;
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
        }
    }

    &.has-quick-actions {
        @media screen and (max-width: 1200px) {
            margin-bottom: 20px;
        }
    }

    &.inline {
        cursor: pointer;
    }

    &.is-small {
        .Range,
        .To {
            padding: 0px;

            input {
                font-size: 14px;
                padding: 8px 6px;
            }

            &.is-set {
            }
        }
    }

    .Wrapper {
        display: flex;
        align-items: center;
    }

    .Range,
    .To {
        color: var(--color-text-black);
        border: solid 1px var(--color-text-inactive);
        border-radius: 5px;
        padding: 0px;

        &.is-month-picker {
            cursor: pointer;

            svg {
                pointer-events: none;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        input {
            padding: 8px 4px;
            border-radius: 5px;
            color: var(--color-text-black);
            outline: none;
            background-color: rgba(0, 0, 0, 0.02);
            font-family: font(regular), sans-serif;
            font-weight: 700;
            font-size: inherit;
            user-select: none;
            border: none;
            margin: 0;
            transition: background 0.3s ease-out;
            cursor: pointer;

            &.is-month-picker {
                width: 140px;
                pointer-events: none;
            }
        }

        svg {
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.02);
            padding: 4px;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        &.is-empty {
            border-color: transparent;
        }

        &.is-set {
            padding: 8px 12px;
        }
    }

    .QuickActions {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        top: unset;
        transform: translateX(-50%) translateY(80%);
        left: 50%;

        button {
            background: var(--color-bg);
            border: 1px solid var(--color-text-inactive);
            border-radius: 5px;
            padding: 2px 4px;
            font-size: 12px !important;
            margin: 0 2px;
            font-family: font(regular), sans-serif;
            color: var(--color-text-black);
            cursor: pointer;
            transition: background 0.3s ease-out;
            background: var(--color-bg);

            * {
                font-size: 13px !important;
            }

            &:hover {
                filter: brightness(0.9);
            }
        }
    }
}

.DatePickerBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 100;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
    }
}

.flatpickr-input {
    //pointer-events: none;
    opacity: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    padding: 0;
    border: none;
    position: absolute;
    bottom: 0;
    right: 34px;
}

.flatpickr-calendar {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
    transition: transform 0.3s ease;
    transform-origin: top;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    border-radius: 12px;
    width: 330px;
    padding: 0 10px 10px;
}

.flatpickr-day.endRange.endRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.startRange.startRange {
    border-radius: 0;
    border-color: var(--color-blue-dark);
    border: none;
    background: color(blue-dark);

    &.selected {
        color: var(--color-text-white);
    }
}

.flatpickr-monthSelect-month {
    color: var(--color-text-black);
    font-family: inherit;

    &:hover {
        background: var(--color-bg);
    }

    &.selected {
        background: var(--color-blue-dark);
    }
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    border-radius: 0;
    border: none;
}

.flatpickr-monthSelect-theme-light {
}

.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.today,
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
    border-color: var(--color-blue-dark);
    border-radius: 0;
}

.flatpickr-calendar {
    .flatpickr-monthDropdown-months,
    .numInputWrapper,
    .flatpickr-next-month,
    .flatpickr-prev-month {
        color: var(--color-text-black);
        fill: var(--color-text-black);
    }

    .flatpickr-next-month,
    .flatpickr-prev-month {
        top: 5px;
    }

    span.flatpickr-weekday {
        color: var(--color-text-black);
    }

    span.flatpickr-day {
        color: var(--color-text-blue);
    }
}

.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 color(blue-light);
}

.flatpickr-months {
    margin: 10px 0 20px;
    align-items: center;
    justify-content: center;

    .flatpickr-month {
        margin: 0;
    }
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    margin: 5px 0;
}
</style>
